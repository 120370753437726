import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FloatingMenu, MainButton, ChildButton } from 'react-floating-button-menu';
import { FaEnvelope, FaBlog, FaUsers, FaCog, FaPlus, FaTimes, FaChartBar, FaUserFriends, FaNewspaper } from 'react-icons/fa';
import NewsletterManagement from './NewsletterManagement';
import BlogManagement from './BlogManagement';
import ContactManagement from './ContactManagement';
import SimulationManagement from './SimulationManagement';

function Dashboard() {
  const [activeSection, setActiveSection] = useState('newsletter');
  const [menuOpen, setMenuOpen] = useState(false); // Toggle floating menu
  const navigate = useNavigate();

  useEffect(() => {
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    const ADMIN_USERNAME = 'h2oasis_admin';
    const ADMIN_PASSWORD = 'a4sl@s26&lmd)ldk';

    if (username !== ADMIN_USERNAME || password !== ADMIN_PASSWORD) {
      navigate('/');
    }
  }, [navigate]);

  const renderSection = () => {
    switch (activeSection) {
      case 'newsletter':
        return <NewsletterManagement />;
      case 'blog':
        return <BlogManagement />;
      case 'contact':
        return <ContactManagement />;
      case 'simulation':
        return <SimulationManagement />;
      default:
        return <div>Section Not Found</div>;
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <header className="bg-green-600 text-white p-4 shadow-lg flex justify-between items-center">
        <h1 className="text-2xl font-bold">Admin Dashboard</h1>
      </header>

      <div className="flex flex-grow">
        {/* Floating Menu with react-floating-button-menu */}
        <div className="fixed bottom-8 right-8 md:right-16 z-20">
          <FloatingMenu
            slideSpeed={500}
            direction="up"
            spacing={10}
            isOpen={menuOpen}
          >
           <MainButton
            iconResting={<FaPlus style={{ fontSize: 20, color: 'white' }} />}
            iconActive={<FaTimes style={{ fontSize: 20, color: 'white' }} />}
            background='#0cc440'
            onClick={() => setMenuOpen(!menuOpen)}
            size={56}
          />

          <ChildButton
            icon={<FaEnvelope style={{ fontSize: 20, color: '#07a6f5' }} />}
            background='white'
            backgroundColor="white"
            size={40}
            onClick={() => {
              setActiveSection('newsletter');
              setMenuOpen(false);
            }}
          >
            <span style={{ position: 'absolute', top: '100%', fontSize: '12px', color: 'gray' }}>Newsletter</span>
          </ChildButton>

          <ChildButton
            icon={<FaNewspaper style={{ fontSize: 20, color: '#07a6f5' }} />}
            backgroundColor="white"
            background='white'
            size={40}
            onClick={() => {
              setActiveSection('blog');
              setMenuOpen(false);
            }}
          >
            <span style={{ position: 'absolute', top: '100%', fontSize: '12px', color: 'gray' }}>Blog</span>
          </ChildButton>

          <ChildButton
            icon={<FaUserFriends style={{ fontSize: 20, color: '#07a6f5' }} />}
            backgroundColor="white"
            background='white'
            size={40}
            onClick={() => {
              setActiveSection('contact');
              setMenuOpen(false);
            }}
          >
            <span style={{ position: 'absolute', top: '100%', fontSize: '12px', color: 'gray' }}>Contacts</span>
          </ChildButton>

          <ChildButton
            icon={<FaChartBar style={{ fontSize: 20, color: '#07a6f5' }} />}
            background='white'
            size={40}
            onClick={() => {
              setActiveSection('simulation');
              setMenuOpen(false);
            }}
          >
            <span style={{ position: 'absolute', top: '100%', fontSize: '12px', color: 'gray' }}>Simulation</span>
          </ChildButton>

          </FloatingMenu>
        </div>

        {/* Content Area */}
        <main className="flex-grow p-6">{renderSection()}</main>
      </div>
    </div>
  );
}

export default Dashboard;
