import { motion } from 'framer-motion';
import image1 from '../../asset/image1.jpg'; // Main image for the presentation

function BriefPresentation() {
  return (
    <div className="relative py-16 px-6 lg:px-20 bg-white">
      {/* Decorative Background Circles */}
      <div className="absolute top-0 left-0 w-64 h-64 bg-blue-200 rounded-full opacity-50 z-0"></div>
      <div className="absolute bottom-0 right-0 w-48 h-48 bg-green-200 rounded-full opacity-50 z-0"></div>

      <div className="relative z-10 flex flex-col lg:flex-row items-center justify-between">
        {/* Text Section */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0"
        >
          <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-800 mb-6">
            Chez H2Oasis,
            <br />
            des solutions pour économiser l'eau
          </h2>
          <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
            Nous développons des solutions innovantes pour économiser jusqu’à
            60% de votre consommation d’eau, sans compromis sur le confort.
            Nos dispositifs, simples à installer, sont conçus pour répondre
            aux besoins des établissements à forte consommation d’eau. Développé
            localement au Maroc, nos équipements contribuent à la préservation
            des ressources tout en réduisant les coûts liés à l’eau et à
            l’énergie.
          </p>
        </motion.div>

        {/* Large Image Section */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          className="lg:w-1/2 flex justify-center lg:justify-end"
        >
          <img
            src={image1}
            alt="H2Oasis Solution"
            className="w-72 h-72 lg:w-96 lg:h-96 rounded-full object-cover shadow-lg z-10"
          />
        </motion.div>
      </div>
    </div>
  );
}

export default BriefPresentation;
