import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loading from '../components/Loading';

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [newBlogTitle, setNewBlogTitle] = useState('');
  const [newBlogContent, setNewBlogContent] = useState('');
  const [newBlogImage, setNewBlogImage] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [loading, setLoading] = useState(true); // Track loading state

  // Fetch blogs from the backend
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://h2oasis-backend-1.onrender.com/api/blogs');
        setBlogs(response.data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  // Handle blog addition
  const handleAddBlog = async () => {
    if (newBlogTitle && newBlogContent && newBlogImage) {
      try {
        const response = await axios.post('https://h2oasis-backend-1.onrender.com/api/blogs', {
          title: newBlogTitle,
          content: newBlogContent,
          author: "Zakaria BAICHA",
          image: newBlogImage,
        });
        setBlogs([...blogs, response.data]);
        setNewBlogTitle('');
        setNewBlogContent('');
        setNewBlogImage(null);
        setIsAdding(false);
      } catch (error) {
        console.error('Error adding blog:', error);
      }
    }
  };

  // Handle image upload and convert it to Base64
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewBlogImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleAddForm = () => {
    setIsAdding(!isAdding);
  };

  return (
    <div className="p-6 mt-16 bg-gray-100 min-h-screen relative">
      {loading && <Loading />} {/* Display Loading component while fetching data */}

      <motion.div
        className="bg-white p-8 rounded-lg shadow-lg"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <h1 className="text-3xl font-semibold mb-6">Articles récents</h1>

        {/* Display recent blogs */}
        {!loading && blogs.map((blog) => (
          <motion.div
            key={blog.id}
            className="bg-gray-100 p-4 rounded-lg mb-4 shadow-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.1 * blog.id }}
          >
            <div className="w-full h-48 overflow-hidden rounded-lg mb-4">
              <img 
                src={blog.image} 
                alt={blog.title} 
                className="w-full h-full object-cover" 
              />
            </div>
            <h2 className="text-xl font-semibold">{blog.title}</h2>
            <p className="text-gray-600 mb-4" dangerouslySetInnerHTML={{ __html: blog.content.slice(0, 100) + "..." }}></p>
            <Link
              to={`/blog/${blog.id}`}
              className="text-blue-500 hover:underline"
            >
              Voir plus
            </Link>
          </motion.div>
        ))}

        {/* Button to toggle Add Blog form */}
        <button
          onClick={toggleAddForm}
          className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
        >
          {isAdding ? 'Annuler' : 'Ajouter un article'}
        </button>

        {/* Add New Blog Form */}
        {isAdding && (
          <motion.div
            className="mt-4 p-4 bg-gray-50 rounded-lg shadow-lg"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-xl font-semibold mb-4">Ajouter un nouvel article</h2>

            <input
              type="text"
              placeholder="Titre de l'article"
              value={newBlogTitle}
              onChange={(e) => setNewBlogTitle(e.target.value)}
              className="w-full p-3 mb-4 border border-gray-300 rounded-lg"
            />

            {/* Rich Text Editor for Content */}
            <ReactQuill
              value={newBlogContent}
              onChange={setNewBlogContent}
              className="mb-4"
              theme="snow"
              placeholder="Contenu de l'article..."
            />

            {/* Image Upload */}
            <input
              type="file"
              onChange={handleImageUpload}
              className="w-full p-3 mb-4 border border-gray-300 rounded-lg"
            />

            {newBlogImage && (
              <div className="w-full h-48 overflow-hidden rounded-lg mb-4">
                <img 
                  src={newBlogImage} 
                  alt="New Blog Preview" 
                  className="w-full h-full object-cover" 
                />
              </div>
            )}

            <button
              onClick={handleAddBlog}
              className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
            >
              Ajouter l'article
            </button>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
}

export default Blogs;
