import React, { useEffect, useState } from 'react';
import StepNavigation from '../components/simulation/StepNavigation';
import InteractiveQuestionnaire from '../components/simulation/InteractiveQuestionaire';
import SimulationStart from '../components/simulation/SimulationStart';
import Results from '../components/simulation/Results';
import Proposition from '../components/simulation/Proposition'; // Import the new Proposition component

function Simulation() {
  const [currentStep, setCurrentStep] = useState(0); // Set to 0 to show the SimulationStart component first
  const [formData, setFormData] = useState(null);
  const [userType, setUserType] = useState(''); // State to track whether the user selected Personal or Professional

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Handler for the user type selection
  const handleSelection = (type) => {
    setUserType(type);
    setCurrentStep(1); // Move to the first step of the questionnaire after selection
    console.log(type);
  };

  // Handler to go to the next step
  const handleNext = () => {
    if (currentStep < 8) {
      setCurrentStep(currentStep + 1);
    }
  };

  // Handler to go to the previous step
  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Handle form submission from InteractiveQuestionnaire
  const handleFormSubmit = (data) => {
    setFormData(data); // Save form data
    console.log(data);
  };

  return (
    <div className="mt-20">
      {/* Display the SimulationStart if no user type is selected */}
      {currentStep === 0 && <SimulationStart onSelection={handleSelection} />}

      {/* Once user selects Personal or Professional, show the questionnaire */}
      {currentStep > 0 && currentStep < 7 && (
        <>
          <StepNavigation currentStep={currentStep} />
          <InteractiveQuestionnaire
            step={currentStep}
            onSubmit={handleFormSubmit}
            onNext={handleNext}
            onPrev={handlePrev}
            userType={userType} // Pass userType here
          />

        </>
      )}

      {/* Show results after step 6 */}
      {currentStep === 7 && formData && (
        <Results formData={formData} onNext={handleNext} />
      )}

      {/* Show Proposition after results */}
      {currentStep === 8 && <Proposition formData={formData} />}
    </div>
  );
}

export default Simulation;
