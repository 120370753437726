import React from 'react';
import { motion } from 'framer-motion';
import logo from '../asset/Logo.png'; // Update with the path to your logo

function Loading() {
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-100 bg-opacity-75 z-50">
      {/* Animated Logo */}
      <motion.img
        src={logo}
        alt="Loading logo"
        className="h-16 md:h-24 lg:h-32"
        animate={{
          y: [0, -20, 0], // Move up and down
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      {/* Loading Text */}
      <motion.div
        className="mt-4 text-lg text-gray-700 font-semibold"
        animate={{ opacity: [0.5, 1, 0.5] }} // Fading effect for text
        transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
      >
        Please wait, loading...
      </motion.div>
    </div>
  );
}

export default Loading;
