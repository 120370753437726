import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import Loading from '../components/Loading';

function NewsletterManagement() {
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await axios.get('https://h2oasis-backend-1.onrender.com/api/newsletter/subscribers');
        setSubscribers(response.data);
      } catch (error) {
        console.error('Error fetching subscribers:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSubscribers();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to unsubscribe this email?')) {
      try {
        await axios.delete(`https://h2oasis-backend-1.onrender.com/api/newsletter/unsubscribe/${id}`);
        setSubscribers((prev) => prev.filter((subscriber) => subscriber.id !== id));
      } catch (error) {
        console.error('Error deleting subscriber:', error);
      }
    }
  };

  // Define columns for DataGrid
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'email', headerName: 'Email', width: 250 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => handleDelete(params.row.id)}
        >
          Unsubscribe
        </Button>
      ),
    },
  ];

  return (
    <div className="bg-white shadow-md rounded p-6" style={{ width: '88vw' }}>
      <h2 className="text-2xl font-semibold mb-4">Manage Newsletter Subscribers</h2>
      {loading ? (
        <Loading />
      ) : subscribers.length === 0 ? (
        <p className="text-center text-gray-500">No subscribers available.</p>
      ) : (
        <DataGrid
          rows={subscribers}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          getRowId={(row) => row.id || Math.random()} // Ensure a unique id for each row
          checkboxSelection
          disableSelectionOnClick
        />
      )}
    </div>
  );
}

export default NewsletterManagement;
