import React, { useState } from 'react';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import axios from 'axios';
function Footer() {
  const [newsletterEmail, setNewsletterEmail] = useState(''); // Email state for newsletter signup
  const [statusNews, setStatusNews] = useState(''); // Status message for feedback
  const handleNewsletterSignup = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post('https://h2oasis-backend-1.onrender.com/api/newsletter/subscribe', {
        email: newsletterEmail,
      });
      setStatusNews(response.data.message);
      console.log(response.data); // Log the response if needed
  
      // Show success status
      setStatusNews('You have successfully subscribed to our newsletter!');
  
      // Clear the form field
      setNewsletterEmail('');
    } catch (error) {
      console.error('Error subscribing to newsletter:', error);
      setStatusNews("An error occurred while subscribing. Please try again.");
    }
  };
  return (
    <footer className="bg-gray-900 text-gray-200 py-12">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 px-4">
        
        {/* Quick Links */}
        <div>
          <h3 className="text-xl font-semibold mb-4">Liens rapides</h3>
          <ul className="space-y-2">
            <li><a href="/solutions" className="hover:text-green-400 transition">Nos Solutions</a></li>
            <li><a href="/why-h2oasis" className="hover:text-green-400 transition">Pourquoi H2Oasis ?</a></li>
            <li><a href="/offers" className="hover:text-green-400 transition">Nos Offres</a></li>
            <li><a href="/blogs" className="hover:text-green-400 transition">Blog</a></li>
            <li><a href="/contact" className="hover:text-green-400 transition">Contact</a></li>
            <li><a href="/privacy-policy" className="hover:text-green-400 transition">Politique de confidentialité</a></li>
          </ul>
        </div>

        {/* Contact Information */}
        <div>
          <h3 className="text-xl font-semibold mb-4">Informations de contact</h3>
          <p>Adresse : H2Oasis, Marrakech, Maroc.</p>
          <p>Téléphone : +212 662 250 767</p>
          <p>Email : <a href="mailto:z.baicha@h2oasis.ma" className="hover:text-green-400 transition">z.baicha@h2oasis.ma</a></p>
        </div>

        {/* Social Media Links */}
        <div>
          <h3 className="text-xl font-semibold mb-4">Suivez-nous</h3>
          <div className="flex space-x-4 text-2xl">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-green-400 transition">
              <FaFacebook />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="hover:text-green-400 transition">
              <FaLinkedin />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-green-400 transition">
              <FaInstagram />
            </a>
          </div>
        </div>

        {/* Newsletter Signup */}
        <div>
          <h3 className="text-xl font-semibold mb-4">Newsletter</h3>
          <p className="mb-4">Inscrivez-vous pour recevoir nos dernières nouvelles.</p>
          <form className="flex flex-col space-y-4" onSubmit={handleNewsletterSignup}>
            <input
              type="email"
              placeholder="Votre email"
              className="px-4 py-2 rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-green-400"
              value={newsletterEmail}
              onChange={(e) => setNewsletterEmail(e.target.value)}
            />
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
            >
              Inscrivez-vous
            </button>
            <p className='px-1 text-white'>{statusNews}</p>
          </form>
        </div>
      </div>

      {/* Copyright */}
      <div className="mt-8 text-center text-gray-500">
        <p>© 2024 H2Oasis. Tous droits réservés.</p>
      </div>
    </footer>
  );
}

export default Footer;
