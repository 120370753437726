import React from 'react';
import { motion } from 'framer-motion';
import { FaHome, FaBuilding } from 'react-icons/fa'; // Import icons for personal and professional
import Logo from '../../asset/Logo.png'; // Update the path to your logo file

function SimulationStart({ onSelection }) {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 mx-5">
      {/* Animated Logo */}
      <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-4 flex flex-col items-center"
        >
          <img src={Logo} alt="H2Oasis Logo" className="h-16 md:h-24 lg:h-32" />
         <h2 className="text-2xl font-bold text-black-700 mb-8">Choisissez le type d'utilisateur</h2>
        </motion.div>

      

      {/* Container for the options */}
      <div className="grid grid-cols-2 gap-8 w-full max-w-4xl">
        
        {/* Personal Option */}
        <motion.div
          onClick={() => onSelection('personal')}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="cursor-pointer bg-green-100 border-2 border-green-400 p-8 rounded-lg text-center flex flex-col items-center justify-center shadow-lg overflow-hidden relative"
        >
          <motion.div
            className="absolute inset-0 bg-green-400 transform translate-y-full transition-all duration-300 ease-in-out"
            whileHover={{ translateY: 0 }}
          />
          <div className="relative z-10 flex flex-col items-center">
            <FaHome size={60} className="text-green-500 mb-4 relative z-10" />
            <span className="text-xl font-semibold text-green-700 relative z-10 hover:text-white">Particulier</span>
          </div>
        </motion.div>
        
        {/* Professional Option */}
        <motion.div
          onClick={() => onSelection('professional')}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="cursor-pointer bg-green-100 border-2 border-green-400 p-8 rounded-lg text-center flex flex-col items-center justify-center shadow-lg overflow-hidden relative"
        >
          <motion.div
            className="absolute inset-0 bg-green-400 transform translate-y-full transition-all duration-300 ease-in-out"
            whileHover={{ translateY: 0 }}
          />
          <div className="relative z-10 flex flex-col items-center">
            <FaBuilding size={60} className="text-green-500 mb-4 relative z-10" />
            <span className="text-xl font-semibold text-green-700 relative z-10 hover:text-white">Professionnel</span>
          </div>
        </motion.div>
        
      </div>
    </div>
  );
}

export default SimulationStart;
