import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import image1 from '../../asset/image1.jpg'; // Main image for the presentation

const testimonials = [
  {
    text: "Grâce aux dispositifs H2Oasis, nous avons réduit notre consommation d'eau de 50% dans nos chambres et économisé 15% sur nos coûts énergétiques liés à l'eau chaude, sans impact sur le confort de nos clients.",
    author: "Hôtel XYZ, Casablanca",
    image: image1,
  },
  {
    text: "L'audit réalisé par H2Oasis nous a permis d'identifier des inefficacités dans notre consommation d'eau. En installant leurs solutions, nous avons réalisé des économies significatives tout en respectant les normes sanitaires strictes de notre secteur.",
    author: "Hôpital ABC, Rabat",
    image: image1,
  },
  {
    text: "Les solutions H2Oasis ont non seulement permis de réduire nos factures d’eau, mais elles ont également sensibilisé nos étudiants à l'importance de la gestion durable de l'eau.",
    author: "Cité universitaire privée DEF, Marrakech",
    image: image1,
  },
];

function TestimonialSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically change testimonials every 6 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 7000);

    return () => clearInterval(interval); // Clean up interval on unmount
  }, [currentIndex]);

  // Handle next testimonial
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  // Handle previous testimonial
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="py-16 px-4 sm:px-6 lg:px-20 bg-gray-50 text-center">
      {/* Introduction */}
      <div className="mb-12">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-800 mb-6">
          Témoignages
        </h2>
        <p className="text-md sm:text-lg text-gray-600">
          Découvrez comment nos clients ont déjà profité des solutions H2Oasis pour
          réduire leur consommation d'eau et d'énergie, tout en préservant le confort et la
          qualité de service de leurs établissements.
        </p>
      </div>

      {/* Testimonial Slider Inside Card */}
      <div className="relative w-full max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-6 sm:p-8 md:p-12">
        <div className="relative h-60 md:h-80 overflow-hidden flex items-center justify-center">
          <AnimatePresence initial={false}>
            <motion.div
              key={currentIndex}
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -100, opacity: 0 }}
              transition={{ duration: 0.6 }}
              className="absolute w-full flex flex-col items-center text-center"
            >
              {/* Author Image */}
              <img
                src={testimonials[currentIndex].image}
                alt={testimonials[currentIndex].author}
                className="w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24 rounded-full mb-4 object-cover border-4 border-gray-200"
              />

              {/* Testimonial Text */}
              <p className="text-xs sm:text-lg md:text-xl font-light text-gray-800 mb-6 leading-relaxed">
                {testimonials[currentIndex].text}
              </p>

              {/* Author */}
              <p className="text-sm sm:text-lg font-semibold text-gray-700">
                – {testimonials[currentIndex].author}
              </p>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>

      {/* Call to Action */}
      <div className="mt-12">
        <p className="text-md sm:text-lg text-gray-800 mb-4">
          Rejoignez nos clients et découvrez comment nos solutions peuvent vous aider à économiser l'eau et l'énergie dans votre établissement.
        </p>
        <button 
        className="px-6 py-2 sm:px-8 sm:py-3 bg-green-600 text-white rounded-full hover:bg-green-500 transition duration-300" 
        onClick={()=>{
          window.location.href = '/contact'
        }}
        >
          Contactez-nous pour en savoir plus
        </button>
      </div>
    </div>
  );
}

export default TestimonialSlider;
