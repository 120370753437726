import React, { useEffect } from 'react'
import OfferSection from '../components/offers/OfferSection'
import OffersParticular from '../components/offers/OffersParticular'
import AuditService from '../components/offers/AuditService'

export default function Offers() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <OfferSection/>
      <OffersParticular/>
      <AuditService/>
    </div>
  )
}
