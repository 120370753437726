import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';
import Loading from '../Loading';

function BlogDetail() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`https://h2oasis-backend-1.onrender.com/api/blogs/${id}`);
        setBlog(response.data);
      } catch (error) {
        console.error('Error fetching blog:', error);
      } finally {
        setLoading(false); // Set loading to false once the data is fetched
      }
    };

    fetchBlog();
  }, [id]);

  // Show Loading component if still fetching data
  if (loading) {
    return <Loading />;
  }

  return (
    <motion.div
      className="p-6 mt-16 bg-gray-100 min-h-screen flex justify-center items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <div className="max-w-3xl bg-white p-8 rounded-lg shadow-lg">
        <div className="w-full h-72 overflow-hidden rounded-lg mb-6">
          <img 
            src={blog.image} 
            alt={blog.title} 
            className="w-full h-full object-cover"
          />
        </div>
        <h1 className="text-3xl font-semibold mb-4">{blog.title}</h1>
        <p className="text-green-600 mb-4">Par {blog.author}</p>
        {/* Use dangerouslySetInnerHTML to render HTML content */}
        <div
          className="text-gray-600 leading-relaxed"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        ></div>
      </div>
    </motion.div>
  );
}

export default BlogDetail;
