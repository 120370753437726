import { motion } from 'framer-motion';
import { useState } from 'react';
import { FaLinkedin, FaEnvelope, FaPhone, FaFacebook, FaInstagram } from 'react-icons/fa';
import logo from '../../asset/Logo.png';
import axios from 'axios';

// Animation Variants
const sectionVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, staggerChildren: 0.2 } },
};

const inputVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const buttonVariant = {
  hover: { scale: 1.1, backgroundColor: '#2563eb', transition: { yoyo: Infinity, duration: 0.4 } },
  tap: { scale: 0.95 },
};

const infoVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.7, delay: 0.5 } },
};

function ContactSection() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [establishment, setEstablishment] = useState('');
  const [establishmentType, setEstablishmentType] = useState('');
  const [message, setMessage] = useState('');
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [status, setStatus] = useState(''); // Status message for feedback

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post('https://h2oasis-backend-1.onrender.com/api/emails/send-email', {
        name,
        email,
        phone,
        establishment,
        establishmentType,
        message,
      });
      
      setStatus('Votre message a été envoyé avec succès.');
      console.log(response.data); // Log the response if needed

      // Clear the form fields
      setName('');
      setEmail('');
      setPhone('');
      setEstablishment('');
      setEstablishmentType('');
      setMessage('');
    } catch (error) {
      console.error('Error sending email:', error);
      setStatus("Une erreur s'est produite lors de l'envoi de votre message. Veuillez réessayer.");
    }
  };

  
  

  return (
    <motion.div
      className="pt-16 px-6 lg:px-20 bg-gradient-to-r from-blue-100 to-blue-50"
      variants={sectionVariant}
      initial="hidden"
      animate="visible"
    >
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-center lg:justify-between space-y-12 lg:space-y-0 lg:space-x-12">
        {/* Contact Form on the Left */}
        <motion.form
          onSubmit={handleSubmit}
          className="w-full lg:w-1/2 bg-white p-8 rounded-lg shadow-lg"
          variants={sectionVariant}
          initial="hidden"
          animate="visible"
        >
          <motion.h2
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-4xl font-bold text-gray-800 mb-6"
          >
            Contactez-nous
          </motion.h2>

          {/* Name */}
          <motion.div variants={inputVariant} className="mb-4">
            <input
              type="text"
              placeholder="Nom complet"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </motion.div>

          {/* Email */}
          <motion.div variants={inputVariant} className="mb-4">
            <input
              type="email"
              placeholder="Adresse e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </motion.div>

          {/* Phone */}
          <motion.div variants={inputVariant} className="mb-4">
            <input
              type="tel"
              placeholder="Numéro de téléphone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </motion.div>

          {/* Establishment Name */}
          <motion.div variants={inputVariant} className="mb-4">
            <input
              type="text"
              placeholder="Nom de l'établissement"
              value={establishment}
              onChange={(e) => setEstablishment(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </motion.div>

          {/* Type of Establishment */}
          <motion.div variants={inputVariant} className="mb-4">
            <select
              value={establishmentType}
              onChange={(e) => setEstablishmentType(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            >
              <option value="">Type d'établissement</option>
              <option value="Maison">Maison</option>
              <option value="Hôtel">Hôtel</option>
              <option value="Hôpital">Hôpital</option>
              <option value="Administration publique">Administration publique</option>
              <option value="Cité universitaire">Cité universitaire</option>
              <option value="Autre">Autre</option>
            </select>
          </motion.div>

          {/* Message */}
          <motion.div variants={inputVariant} className="mb-6">
            <textarea
              placeholder="Message ou demande spécifique (ex: Demander un audit, Demander un devis)"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows="5"
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              
            />
          </motion.div>

          {/* Submit Button */}
          <motion.button
            whileHover={buttonVariant.hover}
            whileTap={buttonVariant.tap}
            type="submit"
            className="w-full py-3 px-6 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-semibold rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-300 transition-transform"
          >
            Envoyer le message
          </motion.button>
          {/* Status message */}
          {status && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="mt-4 text-center text-green-600"
            >
              {status}
            </motion.p>
          )}
        </motion.form>

        {/* Contact Info and Socials */}
        <motion.div
          variants={infoVariant}
          className="w-full lg:w-1/2 flex flex-col items-center space-y-8"
        >
          {/* Logo */}
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, type: 'spring', stiffness: 100 }}
          >
            <img src={logo} alt="H2Oasis Logo" className="w-48 h-auto md:w-72 lg:w-80" />
          </motion.div>

          {/* Direct Contact Information */}
          <motion.div
            className="flex items-center"
          >
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg"
            >
              <FaEnvelope className="text-blue-600 text-3xl mb-4" />
              <p className="text-gray-800 font-semibold">Adresse Email</p>
              <a href="mailto:z.baicha@h2oasis.ma" className="text-blue-600 hover:text-blue-500 transition-colors">
                z.baicha@h2oasis.ma
              </a>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.05 }}
              className="flex flex-col items-center m-3 p-6 bg-white rounded-lg shadow-lg"
            >
              <FaPhone className="text-blue-600 text-3xl mb-4" />
              <p className="text-gray-800 font-semibold">Téléphone</p>
              <a href="tel:+212662250767" className="text-blue-600 hover:text-blue-500 transition-colors">
                +212 662 250 767
              </a>
            </motion.div>
          </motion.div>

          <div className="flex space-x-6">
            {/* Social Media Links */}
            <motion.a
              whileHover={{ scale: 1.05 }}
              href="https://www.linkedin.com/company/h2oasis"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-500 text-3xl transition-colors"
            >
              <FaLinkedin />
            </motion.a>

            <motion.a
              whileHover={{ scale: 1.05 }}
              href="https://www.facebook.com/h2oasis"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-500 text-3xl transition-colors"
            >
              <FaFacebook />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.05 }}
              href="https://www.instagram.com/h2oasis"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-500 text-3xl transition-colors"
            >
              <FaInstagram />
            </motion.a>
          </div>


        </motion.div>
      </div>
    </motion.div>
  );
}

export default ContactSection;

