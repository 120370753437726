import React, { useState } from 'react';
import { motion } from 'framer-motion';
import hotelAtlasImg from '../asset/hotel-atlas.jpg'; // Replace with your actual image path
import waterTechnologyImg from '../asset/image1.jpg'; // Replace with your actual image path
import { useNavigate } from 'react-router-dom';

function WhyUs() {
  const navigate = useNavigate(); 
  const handleShowContact = () => {
    navigate('/contact');
  };

  

  return (
    <div className="h2oasis-page bg-gradient-to-r from-blue-50 to-white py-16 px-6 lg:px-20">
      {/* Introduction Section */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center mb-12 max-w-5xl mx-auto"
      >
        <h1 className="text-5xl font-bold text-gray-800 mt-10 mb-4">Pourquoi H2Oasis ?</h1>
        <p className="text-xl text-gray-600">
          H2Oasis vous aide à économiser de l’eau tout en réduisant vos factures. Découvrez nos technologies et études de cas réelles qui montrent comment nous faisons une différence.
        </p>
      </motion.div>

      {/* Technology Section */}
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="flex flex-col lg:flex-row items-center justify-between mb-12"
      >
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Technologie Innovante</h2>
          <p className="text-lg text-gray-600 mb-4">
            Nos économiseurs optimisent le débit d'eau sans compromis sur le confort, économisant jusqu’à 60% de votre consommation. Notre technologie assure des performances élevées et une installation facile.
          </p>
        </div>
        <div className="lg:w-1/3">
          <motion.img
            src={waterTechnologyImg}
            alt="Water-saving Technology"
            className="w-full rounded-lg shadow-lg"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          />
        </div>
      </motion.div>

      {/* Case Studies Section */}
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        className="flex flex-col lg:flex-row-reverse items-center justify-between mb-12"
      >
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Études de Cas</h2>
          <p className="text-lg text-gray-600 mb-4">
            <strong>L'Hôtel Atlas</strong> a réduit ses factures d'eau de 30% en installant les économiseurs H2Oasis, améliorant ainsi son efficacité et réduisant ses coûts de fonctionnement de manière significative.
          </p>
        </div>
        <div className="lg:w-1/3">
          <motion.img
            src={hotelAtlasImg}
            alt="Hotel Atlas Case Study"
            className="w-full rounded-lg shadow-lg"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          />
        </div>
      </motion.div>

      {/* Call to Action Section */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center bg-gradient-to-r from-blue-500 to-green-500 text-white py-12 px-6 lg:px-20 rounded-lg shadow-lg"
      >
        <h2 className="text-4xl font-bold mb-4">Contactez-nous pour un audit gratuit</h2>
        <p className="text-lg mb-6">
          Remplissez le formulaire ci-dessous et un membre de notre équipe vous contactera pour discuter de vos besoins en économie d'eau.
        </p>
        <button
          onClick={handleShowContact}
          className="bg-white text-blue-600 px-6 py-3 rounded-full hover:bg-gray-100 transition text-lg font-semibold"
        >
          Demandez votre audit gratuit
        </button>
      </motion.div>

     
    </div>
  );
}

export default WhyUs;
