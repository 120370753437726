import React, { useEffect } from 'react'
import Products from '../components/solutions/Products'
import Faq from '../components/solutions/Faq'

export default function Solutions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Products/>
      <Faq/>
    </div>
  )
}
