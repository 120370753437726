import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppBar from './components/AppBar';
import Acceuil from './pages/Acceuil';
import Solutions from './pages/Solutions';
import Offers from './pages/Offers';
import WhyUs from './pages/WhyUs';
import Blogs from './pages/Blogs';
import Contact from './pages/Contact';
import Simulation from './pages/Simulation';
import BlogDetails from './components/blog/BlogDetails';
import Footer from './components/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';

function App() {
  return (
    // Wrap everything inside Router
    <Router>
      <div className="App">
        <AppBar />
        <Routes>
          <Route path="/" element={<Acceuil />} /> {/* Home page */}
          <Route path="/contact" element={<Contact />} /> {/* About Us page */}
          <Route path="/solutions" element={<Solutions />} /> {/* Solutions page */}
          <Route path="/offers" element={<Offers />} /> {/* Offers page */}
          <Route path="/why-h2oasis" element={<WhyUs />} /> {/* Contact page */}
          <Route path="/blogs" element={<Blogs />} /> {/* Contact page */}
          <Route path="/simulation" element={<Simulation />} /> {/* Contact page */}
          <Route path="/blog/:id" element={<BlogDetails />} /> {/* Blog details page */}
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} /> {/* Privacy Policy page */}
          <Route path='/admin/v1/h2oasis/login' element={<Login/>} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
