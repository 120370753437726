import React, { useEffect } from 'react'
import Slider from '../components/acceuil/Slider'
import BriefPresentation from '../components/acceuil/BriefPresentation'
import WaterImpactStatistics from '../components/acceuil/WaterImpactStatistics'
import TestimonialSlider from '../components/acceuil/TestimonialSlider'
import ContactSection from '../components/acceuil/ContactSection'
import logo1 from '../asset/logo1.svg'
import PartnerLogos from '../components/acceuil/PartenaireLogos'
import logo2 from '../asset/logo2.png'
import logo3 from '../asset/logo3.png'
import logo4 from '../asset/logo4.png'
import logo5 from '../asset/logo5.jpeg'

export default function Acceuil() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const logos = [
    { src: logo1, alt: 'CLUSTER EnR' },
    { src: logo2, alt: 'Green Watech' },
    { src: logo3, alt: 'Eco Dome' },
    { src: logo4, alt: 'Bidaya' },
    { src: logo5, alt: 'Orange Corners' }
  ];

  return (
    <div>
      <Slider />
      <BriefPresentation />
      <WaterImpactStatistics />
      <TestimonialSlider />
      <PartnerLogos logos={logos} />
    </div>
  );
}
