import React from 'react';

function PartnerLogos({ logos }) {
  return (
    <div className="container mx-auto py-10">
      <h2 className="text-2xl font-semibold text-center mb-8">Nos Partenaires</h2>
      {/* Adjusted grid for 5 logos */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-6">
        {logos.map((logo, index) => (
          <div key={index} className="flex flex-col items-center justify-center p-4 bg-white shadow-lg rounded-lg hover:shadow-xl transition duration-300 ease-in-out">
            <img
              src={logo.src}
              alt={logo.alt}
              className="max-h-16 object-contain mb-4"
            />
            <p className="text-sm text-center font-medium">{logo.alt}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PartnerLogos;
