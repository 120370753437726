import React, { useState } from 'react';
import { motion } from 'framer-motion';
import logo from '../../asset/Logo.png'; // Replace with your logo path

function SimulateurDevis() {
  const [faucets, setFaucets] = useState(0);
  const [showers, setShowers] = useState(0);
  const [toilets, setToilets] = useState(0);
  const [savings, setSavings] = useState({ water: 0, cost: 0 });

  const calculateSavings = () => {
    // Constants for water usage reduction
    const waterPerFaucet = 0.03; // m³ saved per day per faucet
    const waterPerShower = 0.05; // m³ saved per day per shower
    const waterPerToilet = 0.02; // m³ saved per day per toilet
    const costPerM3 = 8; // Dirhams per m³ (example cost)

    // Calculate total water saved
    const totalWaterSaved =
      faucets * waterPerFaucet + showers * waterPerShower + toilets * waterPerToilet;

    // Calculate cost savings
    const totalCostSaved = totalWaterSaved * costPerM3 * 30; // Monthly savings

    // Set the results
    setSavings({
      water: totalWaterSaved.toFixed(2),
      cost: totalCostSaved.toFixed(2),
    });
  };

  return (
    <div className="simulateur-devis bg-gradient-to-r from-blue-100 to-blue-50 pt-5 pb-9 px-6 lg:px-20">
      {/* Header Section */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center mb-12"
      >
        <img src={logo} alt="H2Oasis Logo" className="w-32 h-auto mx-auto mb-4" />
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Simulateur de devis</h1>
        <p className="text-lg text-gray-600">
          Estimez vos économies d'eau et d'argent en fonction de vos installations actuelles.
        </p>
      </motion.div>

      {/* Form Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <h3 className="text-2xl font-semibold mb-4">Entrez vos informations :</h3>

          {/* Faucets Input */}
          <div className="mb-4">
            <label htmlFor="faucets" className="block text-gray-700 mb-2">
              Nombre de robinets :
            </label>
            <input
              type="number"
              id="faucets"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={faucets}
              onChange={(e) => setFaucets(e.target.value)}
              min="0"
            />
          </div>

          {/* Showers Input */}
          <div className="mb-4">
            <label htmlFor="showers" className="block text-gray-700 mb-2">
              Nombre de douches :
            </label>
            <input
              type="number"
              id="showers"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={showers}
              onChange={(e) => setShowers(e.target.value)}
              min="0"
            />
          </div>

          {/* Toilets Input */}
          <div className="mb-4">
            <label htmlFor="toilets" className="block text-gray-700 mb-2">
              Nombre de chasses d’eau :
            </label>
            <input
              type="number"
              id="toilets"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={toilets}
              onChange={(e) => setToilets(e.target.value)}
              min="0"
            />
          </div>

          {/* Calculate Savings Button */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            className="bg-blue-500 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-600 transition"
            onClick={calculateSavings}
          >
            Estimer mes économies
          </motion.button>
        </div>

        {/* Results Section */}
        <div className="flex flex-col justify-center items-center bg-white p-8 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold mb-6">Résultats estimés :</h3>
          <div className="text-lg text-gray-700 mb-4">
            <p>
              <strong>Économies d'eau :</strong> {savings.water} m³/mois
            </p>
            <p>
              <strong>Économies en dirhams :</strong> {savings.cost} DHS/mois
            </p>
          </div>

          {/* CTA Button */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            className="bg-gradient-to-r from-blue-500 to-green-500 text-white px-6 py-3 rounded-full shadow-lg hover:from-blue-600 hover:to-green-600 transition"
            onClick={() => alert('Demandez un devis détaillé')}
          >
            Demandez un devis détaillé
          </motion.button>
        </div>
      </div>
    </div>
  );
}

export default SimulateurDevis;
