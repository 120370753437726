import React, { useEffect } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend, ArcElement);

function Results({ formData, onNext }) {
  const { monthlyConsumption, savingsPercentage, city } = formData;

  const cityRates = {
    Casablanca: 7,
    Rabat: 6.5,
    Marrakech: 6,
    Tanger: 5.5,
    Fès: 5,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const annualConsumption = monthlyConsumption * 12;
  const annualCost = annualConsumption * (cityRates[city] || 5);
  const potentialSavingsM3 = (annualConsumption * (savingsPercentage / 100)).toFixed(2);
  const potentialSavingsDirhams = (annualCost * (savingsPercentage / 100)).toFixed(2);
  const remainingConsumption = (annualConsumption - potentialSavingsM3).toFixed(2);
  // Data for the bar chart
  const barChartData = {
    labels: ['Consommation actuelle (m³)', 'Économie potentielle (m³)'],
    datasets: [
      {
        label: 'Consommation en m³',
        data: [annualConsumption, potentialSavingsM3],
        backgroundColor: ['#36A2EB', '#4BC0C0'],
      },
    ],
  };
   // Data for the pie chart (Proportion of Savings vs Remaining Consumption)
   const pieChartData = {
    labels: ['Consommation restante', 'Économie potentielle'],
    datasets: [
      {
        data: [remainingConsumption, potentialSavingsM3],
        backgroundColor: ['#36A2EB', '#4BC0C0'],
        hoverBackgroundColor: ['#36A2EB', '#4BC0C0'],
      },
    ],
  };

 

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-4">Résultats</h2>

      <div className="mb-4">
        <h3 className="text-lg font-semibold">Consommation annuelle en m³ :</h3>
        <p>{annualConsumption} m³</p>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold">Coût annuel estimé en dirhams :</h3>
        <p>{annualCost} DHS</p>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold">Économies potentielles avec H2Oasis :</h3>
        <p>Économie en m³ par an : {potentialSavingsM3} m³</p>
        <p>Économie en pourcentage : {savingsPercentage}%</p>
        <p>Économie en dirhams par an : {potentialSavingsDirhams} DHS</p>
      </div>

      {/* Container for side-by-side charts */}
      <div className="mt-6 flex flex-col md:flex-row justify-center items-center gap-4">
        {/* Bar Chart */}
        <div className="chart-container" style={{ maxWidth: '500px', width: '100%' }}>
          <h3 className="text-lg font-semibold text-center mb-4">Comparaison de la consommation</h3>
          <Bar
            data={barChartData}
            options={{
              maintainAspectRatio: true,
              responsive: true,
              plugins: {
                legend: { display: true, position: 'bottom' },
              },
              scales: { y: { beginAtZero: true } },
            }}
          />
        </div>

        {/* Line Chart */}
        <div className="chart-container" style={{ maxWidth: '300px', width: '100%' }}>
          <h3 className="text-lg font-semibold text-center mb-4">Proportion de l'économie potentielle</h3>
          <Pie
            data={pieChartData}
            options={{
              maintainAspectRatio: true,
              responsive: true,
              plugins: {
                legend: { display: true, position: 'bottom' },
              },
            }}
          />
        </div>
      </div>

      {/* Large Button for Proposition */}
      <div className="mt-10">
        <button
          onClick={onNext}
          className="bg-green-500 text-white text-xl font-bold py-4 px-8 rounded-lg shadow-lg hover:bg-green-600 transition-all w-full"
        >
          Voir la Proposition
        </button>
      </div>
    </div>
  );
}

export default Results;
