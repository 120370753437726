import React from 'react';
import { motion } from 'framer-motion';
import homeImg from '../../asset/image2.jpg';
import apartmentImg from '../../asset/image2.jpg';
import villaImg from '../../asset/image2.jpg';
import { useNavigate } from 'react-router-dom';

function OffersParticular() {
  const navigate = useNavigate();
  return (
    <div className="offers-page bg-gradient-to-r from-blue-100 to-blue-50 py-16 px-6 lg:px-20">
      {/* Header with Logo and Title */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center mb-12"
      >
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Nos Offres pour les Particuliers</h1>
        <p className="text-lg text-gray-600">
          Faites des économies à la maison, facilement et rapidement !
        </p>
      </motion.div>

      {/* Main Content Section */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="grid grid-cols-1 lg:grid-cols-2 gap-8"
      >
        {/* Left Section - Images */}
        <div className="flex justify-center items-center space-x-4 flex-wrap">
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
            className="rounded-full overflow-hidden shadow-lg"
          >
            <img src={apartmentImg} alt="Apartment" className="w-40 h-40 object-cover" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
            className="rounded-full overflow-hidden shadow-lg"
          >
            <img src={homeImg} alt="Home" className="w-40 h-40 object-cover" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
            className="rounded-full overflow-hidden shadow-lg"
          >
            <img src={villaImg} alt="Villa" className="w-40 h-40 object-cover" />
          </motion.div>
        </div>

        {/* Right Section - Text Content */}
        <div className="flex flex-col justify-center">
          <p className="text-lg text-gray-700 mb-6">
            Transformez votre consommation d’eau en source d’économies grâce aux packs d’économiseurs d’eau H2Oasis. 
            Nos solutions sont conçues pour s’adapter à tous les types de logements – que vous viviez dans un appartement, 
            une maison ou une villa.
          </p>
          <ul className="text-lg text-gray-700 list-disc ml-6 mb-6">
            <li>Installation simple : S’installe en quelques minutes, sans outils complexes ni plombier.</li>
            <li>Rentabilité rapide : Réduisez jusqu'à 60% de votre consommation d'eau.</li>
            <li>Des économies chaque mois : Observez des économies dès la première utilisation.</li>
          </ul>
          <p className="text-lg text-gray-700 mb-6">
            Prenez le contrôle de vos dépenses d’eau tout en adoptant un mode de vie plus respectueux de l’environnement.
          </p>
          </div>
        </motion.div>
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 justify-center">
          <motion.button
            whileHover={{ scale: 1.05 }}
            className="bg-blue-500 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-blue-600 transition w-full sm:w-auto"
            onClick={() => navigate('/contact')}
          >
            Demandez un devis personnalisé
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            className="bg-gradient-to-r from-blue-500 to-green-500 text-white px-6 py-3 rounded-lg shadow-lg hover:from-blue-600 hover:to-green-600 transition w-full sm:w-auto"
            onClick={() => navigate('/simulation')}
          >
            Essayer notre simulateur d’économie d’eau
          </motion.button>
        </div>
      </div>

  );
}

export default OffersParticular;
