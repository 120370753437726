import { Link, useLocation, useNavigate } from 'react-router-dom'; // Import useLocation
import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiMenu, FiX } from 'react-icons/fi';
import { FaGlobe } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Import translation hook
import logo from '../asset/Logo.png'; // Adjust the logo path
import frenchFlag from '../asset/french-flag.webp'; // Add your flag icons
import englishFlag from '../asset/english-flag.jpg';

function AppBar() {
  const { t, i18n } = useTranslation(); // Translation hook for i18n
  const [navbar, setNavbar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false); // Language dropdown state
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('fr'); // Track selected language
  const navigate = useNavigate(); // To navigate to the contact section

  const location = useLocation(); // To track the current route

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleLangDropdown = () => {
    setIsLangDropdownOpen(!isLangDropdownOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang); // Function to change language
    setSelectedLanguage(lang); // Set the selected language
    setIsLangDropdownOpen(false); // Close dropdown after selecting
  };
  const directTo=(path)=>{
    navigate(path);
    toggleMenu();
  }

  // Determine if the user is on the homepage
  const isHomePage = location.pathname === '/';

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`fixed w-full top-0 left-0 z-50 transition-all duration-500 ${
        isHomePage && !navbar ? 'bg-transparent' : 'bg-white shadow-md'
      }`}
    >
      <div className="container mx-auto px-4 py-2 flex justify-between items-center font-poppins">
        {/* Logo H2Oasis (Left) */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="flex items-center"
        >
          <Link to="/">
            <img src={logo} alt="H2Oasis Logo" className="h-16 transition-colors duration-500" />
          </Link>
        </motion.div>

        {/* Hamburger Menu for Small Screens */}
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className={`text-2xl transition-colors duration-500 ${
              isHomePage && !navbar ? 'text-white' : 'text-black'
            }`}
          >
            {isOpen ? <FiX /> : <FiMenu />}
          </button>
        </div>

        {/* Full Menu for Large Screens (Center) */}
        <motion.ul
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
          className="hidden md:flex space-x-4"
        >
          <li>
            <Link
              to="/"
              className={`transition-colors duration-500 ${
                isHomePage && !navbar ? 'text-white' : 'text-black'
              } hover:text-blue-600 text-md font-medium`}
            >
              {t('home')}
            </Link>
          </li>
          <li
            className="relative"
            onMouseEnter={() => setIsDropdownOpen(true)} // Open dropdown on hover
            onMouseLeave={() => setIsDropdownOpen(false)} // Close dropdown when mouse leaves
          >
            <button
              className={`transition-colors duration-500 ${
                isHomePage && !navbar ? 'text-white' : 'text-black'
              } hover:text-blue-600 text-md font-medium flex items-center`}
            >
              {t('why-us')}
              <span className="ml-1">&#x25BC;</span>
            </button>
            <AnimatePresence>
              {isDropdownOpen && (
                <motion.ul
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="absolute top-full left-0 bg-white shadow-lg rounded-md mt-2 py-1 w-40"
                >
                  <li>
                    <Link to="/solutions" className="block px-4 py-2 text-black hover:bg-blue-100 text-sm">
                      {t('solutions')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/offers" className="block px-4 py-2 text-black hover:bg-blue-100 text-sm">
                      {t('offers')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="block px-4 py-2 text-black hover:bg-blue-100 text-sm">
                      {t('Contact')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/why-h2oasis" className="block px-4 py-2 text-black hover:bg-blue-100 text-sm">
                      {t('why-us')}
                    </Link>
                  </li>
                </motion.ul>
              )}
            </AnimatePresence>
          </li>

          <li>
            <Link
              to="/blogs"
              className={`transition-colors duration-500 ${
                isHomePage && !navbar ? 'text-white' : 'text-black'
              } hover:text-blue-600 text-md font-medium`}
            >
              {t('blog')}
            </Link>
                  
          </li>
          <li>
            <Link
              to="/simulation"
              className={`transition-colors duration-500 ${
                isHomePage && !navbar ? 'text-white' : 'text-black'
              } hover:text-blue-600 text-md font-medium`}
            >
              {t('Simulation')}
            </Link>    
          </li>
        </motion.ul>

        {/* CTA Buttons */}
        <div className="hidden md:flex items-center space-x-4">
          <button className="bg-gradient-to-r from-blue-500 to-green-500 text-white px-4 py-2 rounded-lg shadow-lg hover:from-blue-600 hover:to-green-600 transition text-sm" onClick={()=>{navigate('/simulation')}}>
            {t('cta_quote')}
          </button>
          <button className="bg-gradient-to-r from-white to-gray-200 border border-blue-500 text-blue-500 px-4 py-2 rounded-lg shadow-lg hover:bg-blue-100 transition text-sm" onClick={()=>{navigate('/contact')}}>
            {t('cta_contact')}
          </button>
        </div>

        {/* Language Dropdown with Selected Language */}
        <div className="relative hidden md:flex">
          <button
            onClick={toggleLangDropdown}
            className={`flex items-center ${
              isHomePage && !navbar ? 'text-white' : 'text-black'
            } hover:text-blue-600 text-md`}
          >
            <img
              src={selectedLanguage === 'fr' ? frenchFlag : englishFlag}
              alt={selectedLanguage === 'fr' ? 'Français' : 'English'}
              className="h-5 w-5 mr-2 rounded-full"
            />
            {selectedLanguage === 'fr' ? 'Français' : 'English'}
          </button>
          <AnimatePresence>
            {isLangDropdownOpen && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="absolute right-0 bg-white shadow-lg rounded-lg mt-2 py-2 w-36"
              >
                <ul>
                  <li
                    onClick={() => changeLanguage('fr')}
                    className="flex items-center px-4 py-2 text-sm hover:bg-blue-100 cursor-pointer"
                  >
                    <img src={frenchFlag} alt="Français" className="h-5 w-5 mr-2 rounded-full" />
                    Français
                  </li>
                  <li
                    onClick={() => changeLanguage('en')}
                    className="flex items-center px-4 py-2 text-sm hover:bg-blue-100 cursor-pointer"
                  >
                    <img src={englishFlag} alt="English" className="h-5 w-5 mr-2 rounded-full" />
                    English
                  </li>
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Mobile Dropdown Menu */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ duration: 0.5 }}
              className="fixed top-0 right-0 w-full h-full bg-white shadow-lg md:hidden"
            >
              <div className="absolute top-4 right-6">
                <FiX className="text-2xl text-black cursor-pointer" onClick={toggleMenu} />
              </div>
              <div className="flex flex-col items-center justify-center h-full space-y-6">
                {/* Logo in Mobile Menu */}
                <img src={logo} alt="H2Oasis Logo" className="h-16 mb-6" />

                {/* Mobile Menu Items */}
                <ul className="flex flex-col items-center space-y-4">
                  <li>
                    <Link
                      to="/"
                      onClick={toggleMenu}
                      className="block text-black hover:text-blue-600 text-lg font-semibold"
                    >
                      {t('home')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/blogs"
                      onClick={toggleMenu}
                      className="block text-black hover:text-blue-600 text-lg font-semibold"
                    >
                      {t('blog')}
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={toggleDropdown}
                      className="block text-black hover:text-blue-600 text-lg font-semibold"
                    >
                      {t('why-us')}
                      <span className="ml-1">&#x25BC;</span>
                    </button>
                    {isDropdownOpen && (
                      <ul className="flex flex-col items-center space-y-4 mt-4">
                        <li>
                          <Link
                            to="/solutions"
                            onClick={toggleMenu}
                            className="block text-black hover:text-blue-600 text-lg"
                          >
                            {t('solutions')}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/offers"
                            onClick={toggleMenu}
                            className="block text-black hover:text-blue-600 text-lg"
                          >
                            {t('offers')}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/contact"
                            onClick={toggleMenu}
                            className="block text-black hover:text-blue-600 text-lg"
                          >
                            {t('Contact')}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/why-h2oasis"
                            onClick={toggleMenu}
                            className="block text-black hover:text-blue-600 text-lg"
                          >
                            {t('why-us')}
                          </Link>
                        </li>
                        
                      </ul>
                    )}
                  </li>
                  <li>
                          <Link
                            to="/simulation"
                            onClick={toggleMenu}
                            className="block text-black hover:text-blue-600 text-lg font-semibold"
                          >
                            {t('Simulation')}
                          </Link>
                  </li>

                </ul>

                {/* Language Dropdown in Mobile Menu */}
                <div className="mt-6">
                  <div className="relative">
                    <button
                      onClick={toggleLangDropdown}
                      className="flex items-center text-black hover:text-blue-600 text-lg font-semibold"
                    >
                      <FaGlobe className="mr-2" />
                      {selectedLanguage === 'fr' ? 'Français' : 'English'}
                    </button>
                    {isLangDropdownOpen && (
                      <div className="absolute right-0 bg-white shadow-lg rounded-lg mt-2 py-2 w-36">
                        <ul>
                          <li
                            onClick={() => changeLanguage('fr')}
                            className="flex items-center px-4 py-2 text-sm hover:bg-blue-100 cursor-pointer"
                          >
                            <img src={frenchFlag} alt="Français" className="h-5 w-5 mr-2 rounded-full" />
                            Français
                          </li>
                          <li
                            onClick={() => changeLanguage('en')}
                            className="flex items-center px-4 py-2 text-sm hover:bg-blue-100 cursor-pointer"
                          >
                            <img src={englishFlag} alt="English" className="h-5 w-5 mr-2 rounded-full" />
                            English
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                {/* CTA Buttons in Mobile */}
                <div className="mt-6 flex space-x-4">
                  <button className="bg-gradient-to-r from-blue-500 to-green-500 text-white px-4 py-2 rounded-lg shadow-lg hover:from-blue-600 hover:to-green-600 transition text-sm" onClick={()=>{directTo('/simulation')}}>
                    {t('cta_quote')}
                  </button>
                  <button className="bg-gradient-to-r from-white to-gray-200 border border-blue-500 text-blue-500 px-4 py-2 rounded-lg shadow-lg hover:bg-blue-100 transition text-sm" onClick={()=>{directTo('/contact')}}>
                    {t('cta_contact')}
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.nav>
  );
}

export default AppBar;

