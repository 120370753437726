import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'; // Icons for toggle

const Faq = () => {
  const [expanded, setExpanded] = useState(null);

  // FAQ data
  const faqData = [
    {
      question: "Est-ce que je peux installer ces économiseurs moi-même ?",
      answer: "Oui, sans besoin de plombier."
    },
    {
      question: "Sont-ils compatibles avec tous les systèmes ?",
      answer: "Oui, nos économiseurs s’adaptent aux systèmes standards."
    }
  ];

  // Handle expanding and collapsing
  const toggleFAQ = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className="faq-section bg-white py-12 px-6 lg:px-20 rounded-lg shadow-lg mt-12">
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-3xl font-bold text-center text-gray-800 mb-6"
      >
        FAQ Produits
      </motion.h2>

      <div className="faq-items">
        {faqData.map((faq, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            className="faq-item border-b border-gray-200 py-4"
          >
            {/* Question Section */}
            <div
              onClick={() => toggleFAQ(index)}
              className="faq-question flex justify-between items-center cursor-pointer"
            >
              <h3 className="text-lg font-medium text-gray-800">{faq.question}</h3>
              {expanded === index ? (
                <FiChevronUp className="text-xl text-gray-600" />
              ) : (
                <FiChevronDown className="text-xl text-gray-600" />
              )}
            </div>

            {/* Answer Section */}
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: expanded === index ? 'auto' : 0 }}
              transition={{ duration: 0.5 }}
              className="overflow-hidden"
            >
              <p className="text-gray-600 mt-2">
                {faq.answer}
              </p>
            </motion.div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
