import React, { useState } from 'react';
import { Slider } from '@material-ui/core'; // Slider for percentage

function InteractiveQuestionnaire({ step, onSubmit, onNext, onPrev , userType }) {
  // State for each question
  const [city, setCity] = useState(''); // Question 2: Ville
  const [customCity, setCustomCity] = useState(''); // For custom city input
  const [housingType, setHousingType] = useState(''); // Question 3: Type de logement
  const [waterSource, setWaterSource] = useState(''); // Question 4: Source d’approvisionnement en eau
  const [faucets, setFaucets] = useState(0); // Question 5: Nombre de robinets
  const [showers, setShowers] = useState(0); // Question 5: Nombre de douches
  const [toilets, setToilets] = useState(0); // Question 5: Nombre de chasses d’eau
  const [monthlyConsumption, setMonthlyConsumption] = useState(0); // Question 6: Consommation d'eau mensuelle
  const [useSavings, setUseSavings] = useState(false); // Question 7: Already using water-saving solutions?
  const [savingsPercentage, setSavingsPercentage] = useState(0); // Question 7: Savings percentage

  // Handle change in water source checkboxes
  const handleWaterSourceChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setWaterSource(value);
    } else {
      setWaterSource('');
    }
  };

  // Handler to submit form data
  const handleSubmit = () => {
    const data = {
      city,
      customCity,
      housingType,
      waterSource,
      faucets,
      showers,
      toilets,
      monthlyConsumption,
      useSavings,
      savingsPercentage,
    };
    console.log(userType);
    onSubmit(data); // Submit the form data to parent component
    onNext(); // Automatically go to the next step
  };

  // Content for different steps/questions
  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="bg-white p-8 rounded-lg shadow-lg">

        {/* Step 1: Ville */}
        {step === 1 && (
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Ville</h2>
            <select
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg"
            >
              <option value="">Sélectionnez votre ville</option>
              <option value="Casablanca">Casablanca</option>
              <option value="Rabat">Rabat</option>
              <option value="Marrakech">Marrakech</option>
              <option value="Tanger">Tanger</option>
              <option value="Fès">Fès</option>
              <option value="Agadir">Agadir</option>
              <option value="Autre">Autre</option>
            </select>

            {/* Custom city input if 'Autre' is selected */}
            {city === 'Autre' && (
              <div className="mt-4">
                <label className="block mb-2">Entrez le nom de votre ville :</label>
                <input
                  type="text"
                  value={customCity}
                  onChange={(e) => setCustomCity(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                />
              </div>
            )}
          </div>
        )}

        {/* Step 2: Type de logement */}
        {step === 2 && (
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Type de logement</h2>
            <select
              value={housingType}
              onChange={(e) => setHousingType(e.target.value)}
              className="w-full p-3 border border-gray-300 text-xs lg:text-lg rounded-lg"
            >
              <option value="">Sélectionnez votre type de logement</option>
              
              {userType === 'personal' ? (<option value="Appartement">Appartement</option>) : <option value="Hotel">Hotel</option>}
              {userType === 'personal' ? (<option value="Villa">Villa</option>) : <option value="Administration Publique">Administration Publique</option>}
              {userType === 'personal' ? (<option value="Maison">Maison</option>) : <option value="Hopital">Hopital</option>}
              <option value="Bureau">Bureau</option>
              <option value="Magasin">Magasin</option>
              <option value="Bâtiment">Bâtiment</option>
              <option value="Autre">Autre</option>
            </select>
          </div>
        )}

        {/* Step 3: Source d’approvisionnement en eau */}
        {step === 3 && (
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Source d’approvisionnement en eau</h2>
            <label className="block mb-2">
              <input
                type="checkbox"
                value="Puits"
                checked={'Puits' === waterSource}
                onChange={handleWaterSourceChange}
                className="mr-2"
              />
              Puits
            </label>
            <label className="block mb-2">
              <input
                type="checkbox"
                value="Distributeur"
                checked={'Distributeur' === waterSource}
                onChange={handleWaterSourceChange}
                className="mr-2"
              />
              Distributeur (Redal, Amendis, SRM, etc.)
            </label>
          </div>
        )}

        {/* Step 4: Nombre de points d'eau */}
        {step === 4 && (
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Nombre de points d'eau</h2>
            <div className="mb-4">
              <label className="block mb-2">Nombre de robinets :</label>
              <input
                type="number"
                value={faucets}
                onChange={(e) => setFaucets(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg"
                min="0"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Nombre de douches :</label>
              <input
                type="number"
                value={showers}
                onChange={(e) => setShowers(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg"
                min="0"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Nombre de chasses d’eau :</label>
              <input
                type="number"
                value={toilets}
                onChange={(e) => setToilets(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg"
                min="0"
              />
            </div>
          </div>
        )}

        {/* Step 5: Consommation d’eau mensuelle */}
        {step === 5 && (
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Consommation d’eau mensuelle (m³)</h2>
            <input
              type="number"
              value={monthlyConsumption}
              onChange={(e) => setMonthlyConsumption(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg"
              min="0"
              placeholder="Entrez votre consommation d'eau mensuelle en m³"
            />
            <p className="text-sm text-gray-500 mt-2">
              Vous pouvez trouver cette information sur votre facture d’eau.
            </p>
          </div>
        )}

        {/* Step 6: Utilisez-vous déjà des solutions d'économie d’eau ? */}
        {step === 6 && (
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Utilisez-vous déjà des solutions d'économie d’eau ?</h2>
            <label className="block mb-4">
              <input
                type="checkbox"
                checked={useSavings}
                onChange={(e) => setUseSavings(e.target.checked)}
                className="mr-2"
              />
              Oui
            </label>
            {useSavings && (
              <div className="mb-4">
                <h3 className="text-sm mb-2">Pourcentage d’économie réalisé :</h3>
                <Slider
                  value={savingsPercentage}
                  onChange={(e, val) => setSavingsPercentage(val)}
                  aria-labelledby="savings-slider"
                  min={0}
                  max={100}
                  valueLabelDisplay="auto"
                />
                <p className="text-sm mt-2">Économies actuelles : {savingsPercentage}%</p>
              </div>
            )}
          </div>
        )}

        {/* Navigation Buttons */}
        <div className="flex justify-between mt-6">
          {step > 1 && (
            <button
              onClick={onPrev}
              className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
            >
              Précédent
            </button>
          )}
          {step < 6 ? (
            <button
              onClick={handleSubmit}
              className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
            >
              Suivant
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
            >
              Soumettre
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default InteractiveQuestionnaire;
