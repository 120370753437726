import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from '../components/Loading';

function SimulationManagement() {
  const [propositions, setPropositions] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all propositions
  useEffect(() => {
    const fetchPropositions = async () => {
      try {
        const response = await axios.get('https://h2oasis-backend-1.onrender.com/api/propositions');
        setPropositions(response.data);
      } catch (error) {
        console.error('Error fetching propositions:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPropositions();
  }, []);

  // Delete a proposition by ID
  const deleteProposition = async (id) => {
    if (!window.confirm("Are you sure you want to delete this proposition?")) return;

    try {
      await axios.delete(`https://h2oasis-backend-1.onrender.com/api/propositions/${id}`);
      setPropositions((prev) => prev.filter((proposition) => proposition.id !== id));
    } catch (error) {
      console.error('Error deleting proposition:', error);
    }
  };

  // Define columns for DataGrid
  const columns = [
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'housingType', headerName: 'Housing Type', width: 150 },
    { field: 'monthlyConsumption', headerName: 'Monthly Consumption (m³)', type: 'number', width: 180 },
    { field: 'savingsPercentage', headerName: 'Savings %', type: 'number', width: 120 },
    { field: 'userName', headerName: 'User Name', width: 150 },
    { field: 'userEmail', headerName: 'User Email', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={() => deleteProposition(params.row.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg" style={{ width: '88vw' }}>
      <h2 className="text-2xl font-semibold mb-4">Simulation Management</h2>

      {loading ? (
        <Loading /> // Display Loading component while data is being fetched
      ) : propositions.length === 0 ? (
        <p className="text-center text-gray-500">No propositions available.</p>
      ) : (
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={propositions}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => row.id} // assuming 'id' is the unique identifier for each proposition
            disableSelectionOnClick
            autoHeight
            sx={{
              '& .MuiDataGrid-cell': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
        </div>
      )}
    </div>
  );
}

export default SimulationManagement;
