import React from 'react';
import logo from '../asset/Logo.png'; // Adjust path as needed

function PrivacyPolicy() {
  return (
    <div className="p-8 bg-gray-100 min-h-screen my-8">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl mx-auto">

        {/* Logo */}
        <div className="flex justify-center mb-6">
          <img src={logo} alt="H2Oasis Logo" className="w-32 h-20 md:w-40 md:h-28" />
        </div>

        <h1 className="text-4xl font-bold mb-6 text-center">Politique de Confidentialité</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
          <p className="text-gray-700">
            Bienvenue sur le site web de H2Oasis (ci-après dénommé « le Site »). La confidentialité de vos données est une priorité pour nous.
            La présente Politique de Confidentialité explique comment nous collectons, utilisons, stockons, et protégeons vos informations
            personnelles lorsque vous utilisez notre Site. En accédant ou en utilisant notre Site, vous acceptez les termes de cette Politique de Confidentialité.
            Nous vous recommandons de la lire attentivement.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Informations Collectées</h2>
          <h3 className="text-xl font-semibold mb-2">a. Informations que vous nous fournissez</h3>
          <ul className="list-disc ml-6 text-gray-700">
            <li>Informations de contact : votre nom, adresse e-mail, numéro de téléphone, et toute autre information de contact fournie via nos formulaires de contact ou de devis.</li>
            <li>Informations d’achat : lorsque vous passez commande de nos produits, nous collectons des informations de paiement et des informations nécessaires pour la livraison.</li>
            <li>Messages et communications : les messages que vous nous envoyez, que ce soit par e-mail, chat ou autre.</li>
          </ul>

          <h3 className="text-xl font-semibold mt-4 mb-2">b. Informations collectées automatiquement</h3>
          <p className="text-gray-700">
            Lorsque vous visitez notre Site, certaines informations sont collectées automatiquement, telles que :
          </p>
          <ul className="list-disc ml-6 text-gray-700">
            <li>Informations sur l’appareil : adresse IP, type de navigateur, fournisseur de service Internet (ISP), date/heure de visite.</li>
            <li>Données de navigation : pages visitées, liens cliqués, durée de navigation, etc.</li>
          </ul>

          <h3 className="text-xl font-semibold mt-4 mb-2">c. Cookies et technologies de suivi</h3>
          <p className="text-gray-700">
            Nous utilisons des cookies et des technologies similaires pour collecter des informations sur votre interaction avec notre Site. 
            Ces technologies nous aident à personnaliser votre expérience et à analyser l'utilisation de notre Site. Vous pouvez gérer vos préférences en matière de cookies via les paramètres de votre navigateur.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Utilisation des Informations</h2>
          <p className="text-gray-700">
            Les informations collectées sont utilisées dans les buts suivants :
          </p>
          <ul className="list-disc ml-6 text-gray-700">
            <li>Fournir et améliorer nos services : personnaliser votre expérience, améliorer notre Site et ses fonctionnalités.</li>
            <li>Communication : répondre à vos demandes, vous envoyer des informations sur nos services, des mises à jour, et des offres.</li>
            <li>Marketing : vous envoyer des communications promotionnelles si vous avez consenti à les recevoir.</li>
            <li>Analyse : comprendre l’utilisation de notre Site pour améliorer sa performance et son contenu.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Partage des Informations</h2>
          <p className="text-gray-700">
            Nous ne vendons pas, ne louons pas et ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :
          </p>
          <ul className="list-disc ml-6 text-gray-700">
            <li>Avec votre consentement : lorsque vous nous avez donné votre consentement explicite.</li>
            <li>Fournisseurs de services : avec des tiers qui fournissent des services pour notre compte (par exemple, services de paiement, services d’hébergement, analyse de données).</li>
            <li>Conformité légale : si la loi l'exige, pour répondre à une procédure judiciaire ou pour protéger nos droits.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Sécurité des Données</h2>
          <p className="text-gray-700">
            Nous mettons en œuvre des mesures de sécurité raisonnables pour protéger vos informations personnelles contre tout accès, modification, divulgation ou destruction non autorisés.
            Toutefois, aucun système de transmission ou de stockage électronique n’est totalement sécurisé, et nous ne pouvons garantir la sécurité absolue de vos informations.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Vos Droits</h2>
          <p className="text-gray-700">
            Conformément aux lois applicables, vous avez des droits sur vos données personnelles, notamment :
          </p>
          <ul className="list-disc ml-6 text-gray-700">
            <li>Droit d'accès : obtenir une copie des données personnelles que nous détenons à votre sujet.</li>
            <li>Droit de rectification : corriger les informations inexactes ou incomplètes.</li>
            <li>Droit de suppression : demander la suppression de vos données personnelles, sous certaines conditions.</li>
            <li>Droit d’opposition : vous opposer au traitement de vos données à des fins de marketing direct.</li>
            <li>Droit de retirer votre consentement : lorsque le traitement est basé sur votre consentement, vous pouvez le retirer à tout moment.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. Conservation des Données</h2>
          <p className="text-gray-700">
            Nous conservons vos informations personnelles uniquement pour la durée nécessaire aux fins pour lesquelles elles ont été collectées, ou pour se conformer aux obligations légales.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Modifications de la Politique de Confidentialité</h2>
          <p className="text-gray-700">
            Nous nous réservons le droit de modifier cette Politique de Confidentialité à tout moment. Toute modification sera publiée sur
            cette page avec la date de mise à jour. Nous vous encourageons à consulter cette Politique régulièrement pour rester informé de nos pratiques en matière de confidentialité.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">8. Contact</h2>
          <p className="text-gray-700">
            Pour toute question concernant cette Politique de Confidentialité ou pour exercer vos droits, vous pouvez nous contacter :
          </p>
          <ul className="list-disc ml-6 text-gray-700">
            <li>Email : <a href="mailto:contact@h2oasis.ma" className="text-blue-500">contact@h2oasis.ma</a></li>
            <li>Téléphone : +212 662 250 767</li>
          </ul>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
