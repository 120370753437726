import React from 'react';
import { motion } from 'framer-motion';
import auditImg from '../../asset/image2.jpg'; // Replace with your actual image path
import logo from '../../asset/Logo.png'; // Logo image path
import { useNavigate } from 'react-router-dom';

function AuditService() {
  const navigate = useNavigate();
  return (
    <div className="audit-service-page bg-gradient-to-r from-blue-100 to-blue-50 py-16 px-6 lg:px-20">
      {/* Header with Logo and Title */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center mb-12"
      >
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Service d’audit</h1>
        <p className="text-lg text-gray-600">
          Découvrez vos économies potentielles grâce à notre audit personnalisé !
        </p>
      </motion.div>

      {/* Main Content Section */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="grid grid-cols-1 lg:grid-cols-2 gap-8"
      >
        {/* Left Section - Image */}
        <div className="flex justify-center items-center">
          <motion.img
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
            src={auditImg}
            alt="Audit Service"
            className="rounded-lg shadow-lg w-full lg:w-3/4"
          />
        </div>

        {/* Right Section - Text Content */}
        <div className="flex flex-col justify-center">
          <p className="text-lg text-gray-700 mb-6">
            Vous souhaitez savoir combien vous pourriez économiser en optimisant la consommation d’eau de
            votre établissement ou domicile ? Nos experts H2Oasis réalisent un audit complet et gratuit de
            vos installations actuelles, identifient les points d’amélioration, et vous proposent un plan
            sur mesure pour maximiser vos économies.
          </p>
          <ul className="text-lg text-gray-700 list-disc ml-6 mb-6">
            <li>
              <strong>Analyse précise de vos installations :</strong> Nous évaluons le nombre de points d’eau, vos habitudes
              de consommation et l’efficacité de vos équipements actuels.
            </li>
            <li>
              <strong>Recommandations personnalisées :</strong> Grâce à notre expertise, nous vous suggérons les solutions
              H2Oasis les plus adaptées à vos besoins spécifiques.
            </li>
            <li>
              <strong>Maximisez vos économies :</strong> Avec notre audit, bénéficiez d’un aperçu clair des économies en eau
              et en argent que vous pouvez réaliser sur le court et le long terme.
            </li>
          </ul>

          {/* CTA Button */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            className="bg-gradient-to-r from-blue-500 to-green-500 text-white px-6 py-3 rounded-full shadow-lg hover:from-blue-600 hover:to-green-600 transition"
            onClick={() => navigate('/contact')}
          >
            Demandez votre Audit gratuit
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
}

export default AuditService;
