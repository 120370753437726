import React from 'react';
import { FaBuilding, FaHome, FaWater, FaShower, FaTint, FaPercent } from 'react-icons/fa'; // Add icons
import { motion } from 'framer-motion';

function StepNavigation({ currentStep }) {
  const steps = [
    { step: 1, label: 'Votre besoin', icon: <FaBuilding /> },
    { step: 2, label: 'Votre logement', icon: <FaHome /> },
    { step: 3, label: 'Source d’eau', icon: <FaTint /> },
    { step: 4, label: 'Points d’eau', icon: <FaShower /> },
    { step: 5, label: 'Consommation', icon: <FaWater /> },
    { step: 6, label: 'Économie', icon: <FaPercent /> },
  ];

  const containerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, when: 'beforeChildren', staggerChildren: 0.3 } },
  };

  const itemVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const getStepProgress = () => ((currentStep - 1) / (steps.length - 1)) * 100;

  return (
    <motion.div
      className="flex justify-between my-6 py-4 rounded-lg bg-gray-50 w-full"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      {steps.map((item, index) => (
        <motion.div
          key={index}
          className={`flex flex-col items-center w-full px-2 py-2
            ${item.step <= currentStep ? 'bg-green-500 text-white' : 'bg-gray-100 text-gray-500'}
          `}
          variants={itemVariants}
        >
          <div
            className={`w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full mb-2
              ${item.step <= currentStep ? 'bg-white text-green-500' : 'bg-green-300 text-green-700'}
            `}
          >
            {item.icon}
          </div>
          {/* Hide labels on mobile devices */}
          <span className={`hidden sm:block text-xs md:text-sm font-semibold ${item.step <= currentStep ? 'text-white' : 'text-gray-600'}`}>
            {item.label}
          </span>
        </motion.div>
      ))}

      {/* Progress Bar */}
      <div className="absolute bottom-0 w-full bg-gray-200 h-1 mt-2">
        <motion.div
          className="h-full bg-gradient-to-r from-blue-500 to-green-500"
          initial={{ width: 0 }}
          animate={{ width: `${getStepProgress()}%` }}
          transition={{ duration: 0.5 }}
        />
      </div>
    </motion.div>
  );
}

export default StepNavigation;
