import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import image1 from '../../asset/image1.jpg';
import image2 from '../../asset/image2.jpg';
import image3 from '../../asset/image3.jpg';
import image4 from '../../asset/image4.jpg';
import logo from '../../asset/Logo.png'; // Import your logo image here

const images = [
  image1,
  image2,
  image3,
  image4,
];

function Slider() {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative h-screen w-full">
      <AnimatePresence>
        <motion.div
          key={currentImage}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{ backgroundImage: `url(${images[currentImage]})` }}
        />
      </AnimatePresence>

      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center px-4">
        {/* Logo Animation */}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-4"
        >
          <img src={logo} alt="H2Oasis Logo" className="h-16 md:h-24 lg:h-32" />
        </motion.div>

        {/* Slogan */}
        <motion.h2
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-2xl md:text-5xl lg:text-6xl text-white font-bold text-center tracking-wide leading-tight"
          style={{ textShadow: '2px 2px 10px rgba(0, 0, 0, 0.8)' }}
        >
          Solutions locales,
          <br />
          impact global sur l'eau
        </motion.h2>

        {/* Button */}
        <motion.button
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, delay: 0.6 }}
          className="mt-6 px-6 py-3 bg-gradient-to-r from-blue-500 to-teal-400 text-white font-semibold text-md md:text-xl rounded-full shadow-lg hover:scale-105 hover:from-teal-400 hover:to-blue-500 transition-transform duration-300 ease-in-out"
          onClick={()=>{window.location.href = '/simulation'}}
        >
          Estimez vos économies en eau 
        </motion.button>
      </div>
    </div>
  );
}

export default Slider;
