import React, { useEffect } from 'react'
import ContactSection from '../components/acceuil/ContactSection'
import SimulateurDevis from '../components/devis/SimulateurDevis'

export default function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='mt-10'>
        <ContactSection/>
        <SimulateurDevis/> 
    </div>
  )
}
