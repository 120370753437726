import React from 'react';
import { motion } from 'framer-motion';
import logo from '../../asset/Logo.png'; // Adjust logo path
import enterpriseImg from '../../asset/image1.jpg'; // Replace with your image path
import hospitalImg from '../../asset/image1.jpg'; // Replace with your image path
import universityImg from '../../asset/image1.jpg'; // Replace with your image path
import hotelImg from '../../asset/image2.jpg'; // Replace with your image path
import { useNavigate } from 'react-router-dom';

function OfferSection() {
  const navigate = useNavigate();
  return (
    <div className="offers-page bg-gradient-to-r from-blue-100 to-blue-50 py-16 px-6 lg:px-20">
      {/* Header with Logo and Title */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center mb-12 mt-5"
      >
        <img src={logo} alt="H2Oasis Logo" className="w-32 h-auto mx-auto mb-4" />
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Nos Offres pour les Entreprises</h1>
        <p className="text-lg text-gray-600">
          Optimisez vos ressources, réduisez vos coûts, et adoptez une démarche durable grâce à H2Oasis.
        </p>
      </motion.div>

      {/* Main Content Section */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="grid grid-cols-1 lg:grid-cols-2 gap-8"
      >
        {/* Left Section - Images */}
        <div className="flex justify-center items-center space-x-4 flex-wrap">
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
            className="rounded-full overflow-hidden shadow-lg"
          >
            <img src={hotelImg} alt="Hotel" className="w-40 h-40 object-cover" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
            className="rounded-full overflow-hidden shadow-lg"
          >
            <img src={hospitalImg} alt="Hospital" className="w-40 h-40 object-cover" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
            className="rounded-full overflow-hidden shadow-lg"
          >
            <img src={universityImg} alt="University" className="w-40 h-40 object-cover" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
            className="rounded-full overflow-hidden shadow-lg"
          >
            <img src={enterpriseImg} alt="Enterprise" className="w-40 h-40 object-cover" />
          </motion.div>
        </div>

        {/* Right Section - Text Content */}
        <div className="flex flex-col justify-center">
          <p className="text-lg text-gray-700 mb-6">
            Chez H2Oasis, nous comprenons les défis auxquels les grandes structures comme les hôtels,
            hôpitaux, administrations publiques et cités universitaires font face pour gérer efficacement
            leur consommation d’eau tout en maintenant un niveau de service irréprochable.
          </p>
          <p className="text-lg text-gray-700 mb-6">
            C’est pourquoi nous avons développé des solutions sur mesure, spécifiquement conçues pour
            vous aider à atteindre vos objectifs économiques et environnementaux, sans compromis sur le
            confort ou la qualité des services offerts à vos clients, patients ou employés.
          </p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            className="mt-6 bg-blue-500 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-600 transition"
            onClick={() => navigate('/contact')} // Replace with actual action handler
          >
            Recevoir un échantillon gratuit
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
}

export default OfferSection;
