import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, ResponsiveContainer } from 'recharts';
import { Modal } from '@material-ui/core';

const waterSavingsData = [
  { name: '2018', Hotels: 200, Factories: 300, Agriculture: 400 },
  { name: '2019', Hotels: 240, Factories: 320, Agriculture: 380 },
  { name: '2020', Hotels: 280, Factories: 340, Agriculture: 360 },
  { name: '2021', Hotels: 300, Factories: 400, Agriculture: 340 },
  { name: '2022', Hotels: 350, Factories: 420, Agriculture: 320 },
];

const waterUsageDistribution = [
  { sector: 'Agriculture', percentage: 70 },
  { sector: 'Industry', percentage: 20 },
  { sector: 'Domestic', percentage: 10 },
];

function WaterImpactStatistics() {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  // Handle opening and closing the modal
  const handleOpen = (content) => {
    setModalContent(content);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <div className="py-8 px-4 lg:px-20 bg-white">
      <div className="text-center mb-8">
        <h2 className="text-4xl font-bold text-gray-800">Statistiques sur l'impact de la conservation de l'eau</h2>
        <p className="text-lg text-gray-600 mt-4">
          Explorez comment les solutions H2Oasis ont permis d'économiser des millions de litres d'eau dans différents secteurs.
        </p>
      </div>

      <div className="flex flex-col md:flex-row gap-6">
        {/* Water Savings Line Chart */}
        <div className="flex-1 bg-gray-100 p-4 rounded-lg shadow-lg cursor-pointer" onClick={() => handleOpen('line')}>
          <h3 className="text-xl font-semibold text-gray-700 mb-4 text-center">Économies d'eau par secteur (2018-2022)</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={waterSavingsData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="Hotels" stroke="#8884d8" />
              <Line type="monotone" dataKey="Factories" stroke="#82ca9d" />
              <Line type="monotone" dataKey="Agriculture" stroke="#ffc658" />
            </LineChart>
          </ResponsiveContainer>
          <p className="text-gray-600 text-center mt-2 text-sm">
            Affiche les économies d'eau réalisées par secteur chaque année.
          </p>
        </div>

        {/* Water Usage Distribution Bar Chart */}
        <div className="flex-1 bg-gray-100 p-4 rounded-lg shadow-lg cursor-pointer" onClick={() => handleOpen('bar')}>
          <h3 className="text-xl font-semibold text-gray-700 mb-4 text-center">Répartition de la consommation d'eau par secteur</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={waterUsageDistribution}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="sector" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="percentage" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
          <p className="text-gray-600 text-center mt-2 text-sm">
            La majorité de la consommation d'eau mondiale est utilisée par l'agriculture.
          </p>
        </div>
      </div>

      {/* Modal for Fullscreen Graph */}
      <Modal open={open} onClose={handleClose} className="flex items-center justify-center p-4">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full">
          <button onClick={handleClose} className="text-red-500 mb-4">Cancel</button>
          {modalContent === 'line' && (
            <ResponsiveContainer width="100%" height={500}>
              <LineChart data={waterSavingsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Hotels" stroke="#8884d8" />
                <Line type="monotone" dataKey="Factories" stroke="#82ca9d" />
                <Line type="monotone" dataKey="Agriculture" stroke="#ffc658" />
              </LineChart>
            </ResponsiveContainer>
          )}
          {modalContent === 'bar' && (
            <ResponsiveContainer width="100%" height={500}>
              <BarChart data={waterUsageDistribution}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="sector" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="percentage" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default WaterImpactStatistics;
