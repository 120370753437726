import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from '../components/Loading';

function ContactManagement() {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch contacts
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get('https://h2oasis-backend-1.onrender.com/api/emails');
        setContacts(response.data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchContacts();
  }, []);

  // Delete contact by ID
  const deleteContact = async (id) => {
    if (!window.confirm("Are you sure you want to delete this contact?")) return;

    try {
      await axios.delete(`https://h2oasis-backend-1.onrender.com/api/emails/delete-contact/${id}`);
      setContacts((prev) => prev.filter((contact) => contact.id !== id));
    } catch (error) {
      console.error('Error deleting contact:', error);
    }
  };

  // Define columns for DataGrid
  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'establishment', headerName: 'Establishment', width: 200 },
    { field: 'establishmentType', headerName: 'Type', width: 150 },
    { field: 'message', headerName: 'Message', width: 250, flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 130,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={() => deleteContact(params.row.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg" style={{ width: '88vw' }}>
      <h2 className="text-2xl font-semibold mb-4">Contact Management</h2>

      {loading ? (
        <Loading /> // Show Loading component while loading is true
      ) : contacts.length === 0 ? (
        <p className="text-center text-gray-500">No contacts available.</p> // Display a message if there are no contacts
      ) : (
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={contacts}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => row.id} // assuming 'id' is the unique identifier for each contact
            disableSelectionOnClick
            autoHeight
            sx={{
              '& .MuiDataGrid-cell': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ContactManagement;
