import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing
import faucetImg from '../../asset/faucet-saver.webp'; // Replace with your actual image path
import showerImg from '../../asset/shower-saver.webp'; // Replace with your actual image path
import toiletImg from '../../asset/toilet-saver.webp'; // Replace with your actual image path
import logo from '../../asset/Logo.png'; // Logo image path

function Products() {
  const navigate = useNavigate(); // To navigate to the contact section

  // Function to handle navigation to contact section
  const handleShowContact = () => {
    navigate('/contact'); // Redirect to the homepage and scroll to the contact section
  };

  return (
    <div className="solutions-page bg-gradient-to-r from-blue-100 to-blue-50 py-16 px-6 lg:px-20">
      {/* Header with Logo and Intro */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center mb-12"
      >
        <img src={logo} alt="H2Oasis Logo" className="w-48 h-auto mx-auto mb-4" />
        <h1 className="text-4xl font-bold text-gray-800 mb-2">Découvrez Nos Solutions Économiques</h1>
        <p className="text-lg text-gray-600">
          Nos dispositifs d’économie d’eau sont conçus pour maximiser votre efficacité tout en réduisant vos consommations d'eau. 
          Trouvez la solution adaptée à vos besoins ci-dessous.
        </p>
      </motion.div>

      {/* Products Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 text-center">
        
        {/* Faucet Water Saver */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="product-card bg-white p-6 rounded-lg shadow-lg h-full flex flex-col justify-between"
        >
          <img src={faucetImg} alt="Faucet Water Saver" className="mx-auto mb-6" />
          <h3 className="text-xl font-semibold mb-2">Économiseur d’eau pour robinet</h3>
          <p className="mb-4">
            Ce dispositif s'attache directement à votre robinet pour réduire le débit d'eau tout en
            maintenant une pression suffisante. Une installation facile et rapide, sans besoin de plombier.
          </p>
          <ul className="mb-6">
            <li>Jusqu'à 60% d'économies d'eau</li>
            <li>Facile à installer sans outils spécifiques</li>
            <li>Durable et fabriqué localement</li>
          </ul>
          <button 
            onClick={handleShowContact} 
            className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition"
          >
            Commander maintenant
          </button>
        </motion.div>

        {/* Shower Water Saver */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="product-card bg-white p-6 rounded-lg shadow-lg h-full flex flex-col justify-between"
        >
          <img src={showerImg} alt="Shower Water Saver" className="mx-auto mb-6" />
          <h3 className="text-xl font-semibold mb-2">Économiseur d’eau pour douche</h3>
          <p className="mb-4">
            Optimisez l'utilisation de l'eau de la douche sans sacrifier le confort, grâce à une technologie
            qui ajuste automatiquement le débit en fonction de la pression.
          </p>
          <ul className="mb-6">
            <li>Jusqu'à 30% de réduction d'eau chaude consommée</li>
            <li>Compatible avec tous les systèmes de douche</li>
            <li>Garantie 3 ans</li>
          </ul>
          <button 
            onClick={handleShowContact} 
            className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition"
          >
            Commander maintenant
          </button>
        </motion.div>

        {/* Toilet Water Saver */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="product-card bg-white p-6 rounded-lg shadow-lg h-full flex flex-col justify-between"
        >
          <img src={toiletImg} alt="Toilet Water Saver" className="mx-auto mb-6" />
          <h3 className="text-xl font-semibold mb-2">Économiseur d’eau pour toilette</h3>
          <p className="mb-4">
            Optimisez l'utilisation de l'eau des toilettes sans impacter l’efficacité de votre système,
            grâce à une technologie qui réduit 6L pour chaque utilisation.
          </p>
          <ul className="mb-6">
            <li>Jusqu'à 40% de réduction d'eau consommée</li>
            <li>Compatible avec tous les types de toilette</li>
            <li>Garantie 3 ans</li>
          </ul>
          <button 
            onClick={handleShowContact} 
            className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition"
          >
            Commander maintenant
          </button>
        </motion.div>
      </div>
    </div>
  );
}

export default Products;
